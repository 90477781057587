import React from 'react';
import { Earth, Handshake, ShieldCheck } from 'lucide-react'; 

const MainPage = () => {
  return (
    <main className="flex-grow">
      <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
        <section 
          className="mt-8 mb-12 grid md:grid-cols-2 gap-8 items-center p-14 md:p-22 shadow-md rounded-xl overflow-hidden relative"
          style={{ 
            backgroundImage: "url('/media/main-bg-image.jpg')",
            backgroundSize: 'cover',
            backgroundPosition: 'center'
          }}
        >
          {/* Semi-transparent overlay */}
          <div className="absolute inset-0 bg-black opacity-15"></div>
          
          <div className="space-y-4 relative z-10">
            <h2 className="text-5xl text-white">Renewable energy <span className="text-5xl">in</span><span className="block mt-2 underline">your hands</span></h2>
            <p className="text-xl text-white">
              Energy Democracy is here to provide the easiest, cheapest way to reduce your carbon emissions from electricity.
            </p>
            <p className="text-xl text-white">
              We build, operate and maintain solar farms for communities, and establish renewable energy co-operatives where members own part of the farm and profit from the renewable energy that is generated by it.
            </p>
          </div>
          <div className="flex justify-center relative z-10">
            <img src="/media/main-svg.svg" alt="Energy Democracy Illustration" className="w-full max-w-md h-auto" />
          </div>
        </section>

        <section className="mb-12 text-center py-4">
          <h3 className="text-3xl text-[#703aa4] mb-4">What is a renewable energy co-operative?</h3>
          <p className="text-xl text-gray-600 mb-4">
            As the movement toward cleaner energy gains momentum, a growing number of community-scale renewable energy facilities are being constructed across Australia.
          </p>
          <p className="text-xl text-gray-600">
            Not only do community-scale solar parks capture the economies of scale of larger solar farms, but these renewable energy facilities have advantages over large-scale centralised projects that result in inefficiencies in transmission. On the other hand, individual distributed generation, such as solar infrastructure on the roofs of homes, involves high transaction costs and creates relatively small impacts.
          </p>
        </section>

        <section className="mb-12 text-center">
          <a 
            href="https://energydemocracy.us14.list-manage.com/subscribe?u=531274272281a368367bd1700&id=ab566fa072" 
            target="_blank" 
            rel="noopener noreferrer" 
            className="inline-block"
          >
            <button className="bg-[#ff8400] hover:bg-[#e67600] text-white text-xl font-semibold shadow-md py-2 px-6 rounded-full transition duration-300">
              Subscribe to our newsletter
            </button>
          </a>
        </section>

        <h2 className="text-3xl text-gray-800 text-center mb-8">Community-owned solar benefits</h2>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 my-8">
          {[
            { title: 'Climate Action', icon: <Earth size={62} className="mb-4 text-[#703aa4]" />, description: 'Involvement in positive climate action which lowers your carbon footprint.' },
            { title: 'Community Support', icon: <Handshake size={62} className="mb-4 text-[#703aa4]" />, description: 'Empowering local communities to take control of their energy production.' },
            { title: 'Zero Maintenance', icon: <ShieldCheck size={62} className="mb-4 text-[#703aa4]" />, description: 'Innovative solutions to reduce energy consumption and lower costs.' }
          ].map((item, index) => (
            <div key={index} className="bg-white p-6 rounded-xl shadow-md hover:shadow-xl transition duration-300 text-center">
              <div className="flex justify-center">{item.icon}</div>
              <h3 className="text-xl font-semibold mb-4 text-gray-800">{item.title}</h3>
              <p className="text-gray-600">{item.description}</p>
            </div>
          ))}
        </div>

        <div className="bg-[#703aa4] p-6 rounded-xl shadow-lg mt-8 mb-0 text-center py-4">
          <h2 className="text-3xl mb-4 text-white">Energy Democracy Co-operative</h2>
          <img src="/media/logo-icon.svg" alt="Energy Democracy Logo" className="mx-auto mb-4 w-14 h-14 md:w-22 md:h-22" />
          <p className="text-xl text-white mb-4">
            Our members have invested in solar and batteries and benefit from the financial returns.
          </p>
          <p className="text-xl text-white mb-4">
            Owning shares in our co-operative provides investors with the competitive financial and environmental benefits of renewable energy. The park includes battery storage, which we use to generate extra income for the co-operative that can be distributed to the members.
          </p>
        </div>

        {/* Disclaimer Section */}
        <div className="mb-8 mt-8 pt-8 border-t border-gray-200">
          <hr className="border-t border-gray-600 w-full mb-4" />
            <p className="text-sm text-gray-600">
              * Please read the <a href="/media/Energy%20Democracy%20Disclosure%20Statement-2e79b172bc661b833ded6066d87fd44f.pdf" target="_blank" className="text-[#703aa4] underline">Disclosure Statement</a>. No application for relief under section 741 of the Corporations Act 2001 (Cth) will be sought by the Energy Democracy Central West NSW Co-operative Limited ('the Co-operative') in relation to any statement made in this document as the Chapter 6D disclosure provisions do not apply to members' shares in the Co-operative.
            </p>
        </div>
      </div>            
    </main>
  );
};

export default MainPage;
